import React from 'react'
import {Link} from 'gatsby'

import Layout from '../layouts'
import Nav from '../components/Nav'
import Footer from '../components/Footer'

function PrivacyPage() {
  return (
    <Layout pageName="privacy-shield">
      <Nav/>
      <div className="maxwidth-xsmall">
        <div className="content legal">
          <h1>Privacy Shield</h1>
          <div className="last-revision">Last revision: April 28, 2023</div>

          <ol>
            <li>
              <h3>EU-U.S. and Swiss-U.S. Privacy Shield Policy Notice</h3>
              <p>
                Bizy, LLC. (“We” or “Our”) has certified with the EU-U.S. and Swiss-U.S. Privacy Shield with respect to
                the
                personal data we receive and process on behalf of our customers through our online platform (the
                “Services”). Bizy certifies that it adheres to the Privacy Shield Principles for Personal Data received
                from
                entities in the European Economic Area (the “EEA”), United Kingdom, and Switzerland. This Privacy Shield
                Policy, in conjunction with our Privacy Policy (available here) describe the privacy practices we
                implement
                for any customer data collected worldwide. This Privacy Shield Policy uses terms defined in our general
                Privacy Policy. To learn more about the Privacy Shield program visit <a
                href="https://www.privacyshield.gov">www.privacyshield.gov</a>, and to view our
                certification, please visit <a
                href="https://www.privacyshield.gov/list">https://www.privacyshield.gov/list</a>.
              </p>
            </li>

            <li>
              <h3>Data Collected</h3>
              <p>
                We only collect Personal Data that is relevant to providing our Services. Examples of the types of
                personal
                data collected can be found in our full privacy policy. We take reasonable steps to ensure that the
                Personal
                Data received under the Privacy Shield is needed for Bizy’s Services, and that it is accurate, complete,
                and
                current based on the information you have provided to us.
              </p>
            </li>

            <li>
              <h3>Data Security</h3>
              <p>
                We use reasonable and appropriate physical, electronic, and administrative safeguards to protect
                Personal
                Data from loss, misuse, and unauthorized access, disclosure, alteration, or destruction, taking into
                account
                the nature of the Personal Data and the risks involved in processing that information.
              </p>
            </li>

            <li>
              <h3>Access to Personal Data</h3>
              <p>
                You may contact us at <a href="mailto:privacy@bizy.com">privacy@bizy.com</a> to review, correct, or
                delete
                any personal data. We may limit or deny access to Personal Data where providing such access is
                unreasonably
                burdensome, expensive under the circumstances, or as otherwise permitted by the Privacy Shield
                Principles.
              </p>
            </li>

            <li>
              <h3>Third-Party Data Sharing</h3>
              <p>
                We may share your data with a limited number of third-party providers as outlined in our privacy policy.
                If
                we receive personal data subject to our certification under the Privacy Shield, and then transfer it to
                a
                third-party service provider acting as an agent on our behalf, we have certain liability under the
                Privacy
                Shield if both (i) the agent processes the personal data in a manner inconsistent with the Privacy
                Shield
                and (ii) we are responsible for the event giving rise to the damage.
              </p>
            </li>

            <li>
              <h3>Questions or Complaints:</h3>
              <p>
                If you are a resident of a European country participating in the Privacy Shield and you believe we do
                not
                maintain your personal data within the scope of this Privacy Shield certification, you may direct any
                questions or complaints concerning our Privacy Shield compliance to <a
                href="mailto:privacy@bizy.com">privacy@bizy.com</a> or contact us at our mailing address:
              </p>
              <div className="contacts">
                <div className="contacts__item">
                  <p className="contacts__item-title">
                    <strong>Address:</strong>
                  </p>
                  <p className="contacts__item-text">Bizy, LLC</p>
                  <p className="contacts__item-text">712 H Street NE</p>
                  <p className="contacts__item-text">STE 1802</p>
                  <p className="contacts__item-text">Washington, DC 20002</p>
                </div>
              </div>
              <h5>We will work with you to resolve your issue.</h5>
            </li>

            <li>
              <h3>Dispute Resolution</h3>
              <p>
                In the event we are unable to resolve your concern, you may contact <a
                href="https://www.jamsadr.com/eu-us-privacy-shield">JAMS</a>, which provides an independent
                third-party dispute resolution body based in the United States, and they will investigate and assist you
                free of charge. A binding arbitration option may also be available to you in order to address residual
                complaints not resolved by any other means.
              </p>
            </li>

            <li>
              <h3>Arbitration</h3>
              <p>
                You may also be able to invoke binding arbitration for unresolved complaints, but prior to initiating
                such
                arbitration, a resident of Switzerland or a European country participating in the Privacy Shield must
                first:
                (1) contact us and afford us the opportunity to resolve the issue; (2) seek assistance from <a
                href="https://www.jamsadr.com/eu-us-privacy-shield">JAMS</a>; and (3)
                contact the U.S. Department of Commerce (either directly or through a European Data Protection
                Authority)
                and afford the Department of Commerce time to attempt to resolve the issue. If such a resident invokes
                binding arbitration, each party shall be responsible for its own attorney’s fees. Please be advised
                that,
                pursuant to the Privacy Shield, the arbitrator(s) may only impose individual-specific, non-monetary,
                equitable relief necessary to remedy any violation of the Privacy Shield Principles with respect to the
                resident.
              </p>
            </li>

            <li>
              <h3>U.S. Federal Trade Commission Enforcement</h3>
              <p>
                Our Privacy Shield compliance is subject to the investigatory and enforcement powers of the U.S. Federal
                Trade Commission (FTC).
              </p>
            </li>

            <li>
              <h3>Requirement to Disclose</h3>
              <p>
                We may disclose personal data when we have a good faith belief that such action is necessary to: conform
                to
                legal requirements or to respond to lawful requests by public authorities, including to meet national
                security or law enforcement requirements; or to enforce our contractual obligations.
              </p>
            </li>

            <li>
              <h3>Changes to This Policy</h3>
              <p>
                We may make changes to this policy from time to time in conjunction with our privacy policy and in
                compliance with the Privacy Shield. You can refer to the most recent revision date (labeled as "Last
                updated
                on") at the top of this policy.
              </p>
            </li>
          </ol>
        </div>
      </div>
      <Footer/>
    </Layout>
  )
}

export default PrivacyPage
